@import '../theme-bootstrap';

.quickshop-wrapper {
  clear: both;
  position: relative;
  top: -15px;
  &.active {
    .quickshop-arrow {
      @include bordered-triangle(17px, $color-light-gray, up, $color-black);
      @include transition(left 300ms ease);
      border-bottom: solid 1px $color-light-gray;
      position: absolute;
      top: -1px;
      z-index: 1;
      left: 70%;
      margin-left: -9px;
      @include breakpoint($medium-up) {
        top: 0;
      }
    }
    @include breakpoint($medium-up) {
      &--1 .quickshop-arrow {
        left: 16.6667%;
        .product-grid--medium-cols-4 & {
          left: 12.5%;
        }
      }
      &--2 .quickshop-arrow {
        left: 50%;
        .product-grid--medium-cols-4 & {
          left: 37.5%;
        }
      }
      &--3 .quickshop-arrow {
        left: 83.3333%;
        .product-grid--medium-cols-4 & {
          left: 62.5%;
        }
      }
      &--4 .quickshop-arrow {
        left: 87.5%;
      }
    }
  }
}

.product-quickshop {
  width: 100%;
  max-width: $max-width-large;
  margin: 0 auto;
  &__main {
    position: relative;
    top: 15px;
    padding: 25px 20px 30px;
    width: 100%;
    background: $color-light-gray;
    background: rgba($color-green, 0.1);
    border-bottom: 1px solid $color-black;
    @include breakpoint($medium-up) {
      border-top: 1px solid $color-black;
      display: table;
      padding: 25px 0;
    }
    @include breakpoint($largest-up) {
      border-left: 1px solid $color-black;
      border-right: 1px solid $color-black;
    }
    @include breakpoint($medium-up) {
      .results & {
        padding: 25px 20px 30px;
      }
    }
  }
  &__product {
    @include breakpoint($medium-up) {
      display: table-cell;
      width: 66.6666%;
      padding: 20px 50px;
      border-right: 1px solid $color-light-gray;
    }
  }
  &__quotes {
    display: none;
    @include breakpoint($medium-up) {
      display: table-cell;
      width: 33.3333%;
    }
  }
  .product-heading {
    text-align: center;
    border-bottom: solid 1px $color-light-gray;
    margin-bottom: 20px;
    @include breakpoint($medium-up) {
      border-bottom: none;
      text-align: left;
      margin-bottom: 0;
    }
    &__name {
      font-size: 13px;
      margin: 0 0 4px;
      @include breakpoint($medium-up) {
        margin: 0 0 2px;
        font-size: 16px;
      }
    }
    &__subheading {
      font-size: 24px;
      margin: 0 0 16px;
      @include breakpoint($medium-up) {
        font-size: 34px;
      }
    }
  }
  &__description {
    @include font--text;
    font-size: 16px;
    line-height: 20px;
    padding: 0 0 20px;
    border-bottom: solid 1px $color-light-gray;
    margin: 0 0 17px;
    display: none;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
  &__sku-select {
    padding: 0 0 20px;
    border-bottom: solid 1px $color-light-gray;
    &__label {
      @include breakpoint($small-down) {
        float: none;
      }
      @include font--cta;
      font-size: 14px;
      margin: 0 0 15px;
      float: left;
      @include breakpoint($medium-up) {
        margin-right: 10px;
      }
    }
  }
  &__footer {
    text-align: center;
    padding: 15px 0 0;
    @include breakpoint($medium-up) {
      text-align: left;
      display: table;
      width: 100%;
    }
    .product-add-to-bag,
    .product-add-to-waitlist {
      width: 100%;
      @include breakpoint($medium-up) {
        width: auto;
        min-width: auto;
      }
    }
    &__price,
    &__actions,
    &__add-to-bag {
      @include breakpoint($medium-up) {
        display: table-cell;
        width: 45%;
      }
    }
    &__actions {
      width: 10%;
      text-align: center;
      margin-bottom: 20px;
      @include breakpoint($medium-up) {
        margin-bottom: 0;
      }
      .icon-text {
        margin: 5px 0 0;
        display: block;
        @include breakpoint($medium-up) {
          display: none;
        }
      }
    }
    &__add-to-bag {
      text-align: center;
      margin-bottom: 10px;
    }
    &.product-tos {
      .product-quickshop__footer {
        &__price,
        &__add-to-bag {
          @include breakpoint($medium-up) {
            width: 45%;
          }
        }
        &__actions {
          width: 10%;
        }
      }
    }
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
    cursor: pointer;
  }
}

.pre-order {
  &-active {
    background-color: $color-green;
    outline: solid 1px $color-green;
    &:hover {
      outline: solid 1px $color-green;
    }
  }
  &__status {
    .pre-order_msg {
      font-family: $font--futura-book;
      font-size: 14px;
      padding: 5px 13px 15px 13px;
      @include breakpoint($medium-up) {
        padding: 5px 13px 15px 13px;
      }
    }
  }
  &-message-display {
    padding: 10px 0;
  }
  &__items-msg {
    padding-top: 10px;
  }
  &__items-layout {
    padding: 0 0 10px 18px;
  }
  &_search_pc {
    float: right;
    width: 42%;
    margin: 5px 0;
  }
}

.mpp_pre_order_status {
  @include breakpoint($medium-up) {
    float: right;
  }
}

#pre-order__detail {
  color: $color-blue;
  text-decoration: underline;
}

.order-summary {
  &__pre_order_items_only {
    font-size: 16px;
  }
}

.preorder-rating {
  &__hide-display {
    display: none;
  }
}
